import React, { useState } from 'react';
import axios from 'axios';

function Login({ onLogin }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            //const response = await axios.post('http://localhost:5000/login', { username, password });
            const response = await axios.post('https://chrislilley.ca/backend/login', { username, password });
            if (response.data.success) {
                localStorage.setItem('token', response.data.access_token); // Store the JWT token
                onLogin(username); // Pass the username back to the parent component
            } else {
                setError('Invalid username or password');
                console.log(response.data);
            }
        } catch (error) {
            setError('Login failed. Please try again.');
        }
    };

    return (
        <div>
            {error && <p>{error}</p>}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Username:</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>
                <div>
                    <label>Password:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <button type="submit">Login</button>
            </form>
        </div>
    );
}

export default Login;
