import React, { useState } from 'react';
import './searchresults.css';

export default function SearchResults({ results }) {
    const [expandedIds, setExpandedIds] = useState(new Set());

    // Toggle the expanded state of an item
    const toggleExpand = (id) => {
        setExpandedIds(prevState => {
            const newExpandedIds = new Set(prevState);
            if (newExpandedIds.has(id)) {
                newExpandedIds.delete(id);
            } else {
                newExpandedIds.add(id);
            }
            return newExpandedIds;
        });
    };

    // Render a single row
    const renderRow = (result, isNested = false) => {
        // Determine if this item has relations to show (for expand/collapse icon)
        const hasRelations = result.originId === result.Id && results.some(item => item.originId === result.Id && item.Id !== result.Id);
        const isExpanded = expandedIds.has(result.Id);
        const rowClass = isNested ? 'nested-row' : '';
        const expandCollapseIcon = hasRelations ? (isExpanded ? '-' : '+') : '';

        return (
            <tr key={result.Id} className={rowClass} onClick={() => !isNested && toggleExpand(result.Id)}>
                <td>
                    {hasRelations && (
                        <span onClick={(e) => {
                            e.stopPropagation(); // Prevent row click when clicking the icon
                            toggleExpand(result.Id);
                        }}>
                            {expandCollapseIcon}
                        </span>
                    )}
                </td>
                <td>{result.ResourceType}</td>
                <td>{result.Id}</td>
                <td>{result.Name}</td>
                <td>{result.EntityState ? result.EntityState.Name : ''}</td>
                <td>{result.Priority ? result.Priority.Name : ''}</td>
                <td>
                    {result.CustomFields &&
                        result.CustomFields.find(field => field.Name === 'Priority (P)')?.Value}
                </td>
                <td>{result.MasterRelations &&
                    result.MasterRelations.Items.length > 0 && (
                        <span>
                            {result.MasterRelations.Items.map((item, index) => (
                                <span key={index}>
                                    {item.Master.Id}
                                    {index < result.MasterRelations.Items.length - 1 && ", "}
                                </span>
                            ))}
                            {result.SlaveRelations &&
                                result.SlaveRelations.Items.length > 0 && ", "}
                        </span>
                    )}
                    {result.SlaveRelations &&
                        result.SlaveRelations.Items.length > 0 && (
                            <span>
                                {result.SlaveRelations.Items.map((item, index) => (
                                    <span key={index}>
                                        {item.Slave.Id}
                                        {index < result.SlaveRelations.Items.length - 1 && ", "}
                                    </span>
                                ))}
                            </span>
                        )}</td>
                <td>{result.Release ? result.Release.Name : ''}</td>
                <td>{result.TeamIteration ? result.TeamIteration.Name : ''}</td>
                <td>{result.Build ? result.Build.Name : ''}</td>
            </tr>
        );
    };

    // Render rows with their nested rows if they have relations and are expanded
    const renderRowsWithRelations = () => {
        return results.map((result) => {
            // Initialize an empty array to hold the rows for this result
            const rows = [];

            // Check if this is an origin row; if so, render it
            if (result.originId === result.Id) {
                rows.push(renderRow(result));
            }

            const isExpanded = expandedIds.has(result.Id);

            // Check if the current item should show nested related items
            if (isExpanded && result.originId === result.Id) {
                // Filter related items that have the same originId as the current item
                const relatedItems = results.filter(item => item.originId === result.Id && item.Id !== result.Id);
                relatedItems.forEach(relatedItem => {
                    // Render each related item as a nested row
                    rows.push(renderRow(relatedItem, true));
                });
            }

            return rows;
        }).flat(); // Flatten the array of arrays into a single array of rows
    };

    if (!results) {
        return <div>Please enter a TP Item Number</div>;
    }

    if (typeof results === 'object' && !Array.isArray(results) && results.status === '400') {
        return <div>Issue with TP item</div>;
    }

    if (Array.isArray(results) && results.length === 0) {
        return <div>No results found</div>;
    }

    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>Type</th>
                        <th>ID</th>
                        <th>Name</th>
                        <th>State</th>
                        <th>Priority</th>
                        <th>Priority (P)</th>
                        <th>Related TP Items</th>
                        <th>Release</th>
                        <th>Team Iteration</th>
                        <th>Build</th>
                    </tr>
                </thead>
                <tbody>
                    {renderRowsWithRelations()}
                </tbody>
            </table>
        </div>
    );
}
