import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SearchBar from './SearchBar';
import SearchResults from './searchresults';
import Login from './Components/Login';
import logo from './assets/lely.jpg';
import './App.css';

// Create an axios instance
const api = axios.create({
  //baseURL: 'http://localhost:5000', // Use local Flask server
  baseURL: 'https://chrislilley.ca/backend',
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

function App() {
  const [user, setUser] = useState(null); // Track the user's login status
  const [results, setResults] = useState([]);

  useEffect(() => {
    // No initial data fetch here, it will be based on user input
  }, []);

  const handleLogin = (username) => {
    setUser({ username }); // Assuming login is successful, set the user
  };

  const handleLogout = () => {
    setUser(null); // Logout the user
    localStorage.removeItem('token'); // Clear the token on logout
    // Perform any cleanup or redirection as needed
  };

  const fetchData = async (ids) => {
    try {
      const response = await api.get(`/search/${ids}`);
      setResults(response.data); // Set the results when data is fetched
    } catch (error) {
      console.error('Error fetching data:', error);
      setResults([]); // Reset results on error
    }
  };

  const handleSearch = (query) => {
    fetchData(query); // Fetch data based on the user's input
  };

  return (
    <div className="App">
      <div className="header">
        <img src={logo} alt="Pic Logo" />
        <h1>Target Process Search</h1>
      </div>
      {user ? (
        <>
          <div className='header'>
            <SearchBar onSearch={handleSearch} />
          </div>
          <div>
            <SearchResults results={results} />
          </div>
          <button className='logout' onClick={handleLogout}>Logout</button>
        </>
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </div>
  );
}

export default App;
